import {
  URL_PARAMS,
  ENVIRONMENTS,
  LOCAL_URLS,
  DEV_URLS,
  SANDBOX_URLS,
  STAGING_URLS,
  PRODUCTION_URLS,
} from 'src/constants'

import { urlParam, logger } from 'src/utils'

export interface EnvironmentSettings {
  checkoutFrontEndBase: string
  subscriptionManagementFrontEndBase: string
  checkoutBase: string
  analyticsKey: string
  affiliateAnalyticsKey: string
  pricesApi: string
  dataApi: string
  orderApi: string
  audienceApi: string
  userHistoryApi: string
}

export interface EnvProps {
  current: ENVIRONMENTS
  defaults: {
    local: EnvironmentSettings
    dev: EnvironmentSettings
    staging: EnvironmentSettings
    sandbox: EnvironmentSettings
    production: EnvironmentSettings
  }
}

class _Environment {
  env: EnvProps
  constructor() {
    // Environment Defaults
    this.env = {
      current: ENVIRONMENTS.PRODUCTION,
      defaults: {
        [ENVIRONMENTS.LOCAL]: {
          analyticsKey: '8b03159305e2c0f49bf7481c073a4819',
          affiliateAnalyticsKey: '8be7b8d69526697e7ae22aff30d34603',
          ...LOCAL_URLS,
        },
        [ENVIRONMENTS.DEVELOPMENT]: {
          analyticsKey: '8b03159305e2c0f49bf7481c073a4819',
          affiliateAnalyticsKey: '8be7b8d69526697e7ae22aff30d34603',
          ...DEV_URLS,
        },
        [ENVIRONMENTS.SANDBOX]: {
          analyticsKey: '8b03159305e2c0f49bf7481c073a4819',
          affiliateAnalyticsKey: '8be7b8d69526697e7ae22aff30d34603',
          ...SANDBOX_URLS,
        },
        [ENVIRONMENTS.STAGING]: {
          analyticsKey: '8b03159305e2c0f49bf7481c073a4819',
          affiliateAnalyticsKey: '8be7b8d69526697e7ae22aff30d34603',
          ...STAGING_URLS,
        },
        [ENVIRONMENTS.PRODUCTION]: {
          analyticsKey: '3cca81641d7d36dd0223d8a5615ae36a',
          affiliateAnalyticsKey: '05150e015258048ddbc1aa7188718750',
          ...PRODUCTION_URLS,
        },
      },
    }
  }

  detect() {
    if (
      typeof urlParam(URL_PARAMS.PADDLE_ENVIRONMENT) != 'undefined' &&
      urlParam(URL_PARAMS.PADDLE_ENVIRONMENT) !== ''
    ) {
      logger.log('Environment Detected: ' + urlParam(URL_PARAMS.PADDLE_ENVIRONMENT))
      this.set(urlParam(URL_PARAMS.PADDLE_ENVIRONMENT))
    } else {
      logger.log('Environment Detected: ' + this.get())
    }
  }

  get() {
    return this.env.current
  }

  set(environment: ENVIRONMENTS) {
    logger.log('Changing environment to: ' + environment)
    this.env.current = environment
  }

  defaults() {
    return this.env.defaults[this.env.current]
  }
}

export const Environment = new _Environment()
