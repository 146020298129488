import { LOAD_METHOD } from 'src/constants'

import { EventProps } from 'src/utils/analytics'
import { logger } from 'src/utils/logger'

interface sdkAttributesProps {
  bundleIdentifier?: string
  appName?: string
}

export interface OptionsProps {
  vendor: number | null
  debug?: boolean
  enableTracking?: boolean
  poweredByBadge?: boolean
  loadMethod?: LOAD_METHOD
  eventCallback: null | ((eventData: EventProps) => void)
  sdk?: boolean
  sdkAttributes?: sdkAttributesProps | null
  completeDetails?: boolean
  upsellCheckbox?: boolean
  checkoutVariant?: null
}

class _Options {
  options: OptionsProps
  constructor() {
    this.options = {
      vendor: null, // Needs to be overridden for Pageview tracking to work.
      debug: false,
      enableTracking: true, // NOTE: No longer used, kept for backwards compatibility with the SDK
      poweredByBadge: false,
      loadMethod: LOAD_METHOD.DIRECT, // Direct or Migrate
      eventCallback: null, // Global event handler/callback
      sdk: false,
      sdkAttributes: null,
      completeDetails: false, // Automatic checkout complete popup.
      upsellCheckbox: false,
      checkoutVariant: null, // leave this here so Options does not throw an error if the option is provided (it has been deprecated)
    }
  }

  set(options: OptionsProps) {
    if (typeof options == 'object') {
      // Loop through the 'options' object that is passed to this function.
      for (let option in options) {
        // Check that the option they're trying to override is one we support overriding.
        if (this.options.hasOwnProperty(option)) {
          // Validate the 'vendor' option if set.
          if (option === 'vendor') {
            if (options.vendor !== parseInt(`${options.vendor}`, 10)) {
              throw new Error("[PADDLE] The option parameter 'vendor' must be an integer.")
            }

            // The ID 1234567 is reserved for examples, if someone uses this, throw an error.
            if (options[option] === 1234567) {
              throw new Error(
                "[PADDLE] You must specify a valid Paddle Vendor ID for the 'vendor' attribute within the Paddle.Setup() or Paddle.Options() method. The provided Vendor ID '1234567' is invalid and is used for example purposes. See: https://developer.paddle.com/guides/how-tos/checkout/paddle-checkout",
              )
            }
          } else if (option === 'sdkAttributes') {
            this.options.sdkAttributes = options.sdkAttributes
          }

          // Overwrite the this.options object with the new defined values.
          logger.log("Set option '" + option + "' to '" + options[option] + "'.")

          if (option !== 'sdkAttributes') {
            this.options[option] = options[option]
          }
        } else {
          // If it's not an option we support overriding, throw an error.
          throw new Error("[PADDLE] Unknown option parameter '" + option + "'")
        }
      }
    } else {
      throw new Error('[PADDLE] The Options() method accepts an object of options values.')
    }
  }

  get vendor() {
    return this.options.vendor
  }

  get debug() {
    return this.options.debug
  }

  get enableTracking() {
    return this.options.enableTracking
  }

  get poweredByBadge() {
    return this.options.poweredByBadge
  }

  get loadMethod() {
    return this.options.loadMethod
  }

  get eventCallback() {
    return this.options.eventCallback
  }

  get sdk() {
    return this.options.sdk
  }

  get sdkAttributes() {
    return this.options.sdkAttributes
  }

  get completeDetails() {
    return this.options.completeDetails
  }

  get upsellCheckbox() {
    return this.options.upsellCheckbox
  }

  get checkoutVariant() {
    return this.options.checkoutVariant
  }
}

export const Options = new _Options()
