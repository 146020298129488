export enum CLASSES {
  PADDLE_BUTTON = 'paddle_button',
  PADDLE_HIDDEN = 'paddle-hidden',
  PADDLE_VISIBLE = 'paddle-visible',
  PADDLE_POPUP = 'paddle-popup',
  PADDLE_POPUP_CLOSE = 'paddle-popup-close',
  PADDLE_POPUP_CONTAINER = 'paddle-popup-container',
  PADDLE_POPUP_INNER = 'paddle-popup-inner',
  PADDLE_POPUP_HEADING = 'paddle-popup-heading',
  PADDLE_POPUP_SUB_HEADING = 'paddle-popup-subheading',
  PADDLE_POPUP_CLOSE_IMAGE = 'paddle-popup-close-image',
  PADDLE_POPUP_FORM = 'paddle-popup-form',
  PADDLE_POPUP_FIELD = 'paddle-popup-field',
  PADDLE_POPUP_EMAIL = 'paddle-popup-email',
  PADDLE_ANIMATED = 'paddle-animated',
  PADDLE_POPUP_CHECKBOX = 'paddle-popup-checkbox',
  PADDLE_POPUP_CHECKBOX_INPUT = 'paddle-popup-checkbox-input',
  PADDLE_POPUP_CHECKBOX_LABEL = 'paddle-popup-checkbox-label',
  PADDLE_POPUP_CTA = 'paddle-popup-cta',
  PADDLE_FADE_IN = 'paddle-fadeIn',
  PADDLE_RESET = 'paddle-reset',
  PADDLE_POPUP_INSTANCE_ID = 'paddle-popup-instance_',
  PADDLE_DETAILS_POPUP_INTERIM_MESSAGE_SMALL = 'paddle-details-popup-interim-message-small',
  PADDLE_DETAILS_POPUP_INTERIM_MESSAGE = 'paddle-details-popup-interim-message',
  PADDLE_DETAILS_POPUP_INTERIM_TITLE = 'paddle-details-popup-interim-title',
  PADDLE_LOADER = 'paddle-loader',
  PADDLE_FRAME = 'paddle-frame',
  PADDLE_FRAME_INLINE = 'paddle-frame-inline',
  PADDLE_FRAME_OVERLAY = 'paddle-frame-overlay',
  PADDLE_STYLED_BUTTON = 'paddle_styled_button',
  GREEN = 'green',
  LIGHT = 'light',
  DARK = 'dark',
  PADDLE_DOWNLOAD = 'paddle_download',
  PADDLE_BOUNCE_IN = 'paddle-bounceIn',
  PADDLE_INSET_CLOSE = 'paddle-inset-close',
  PADDLE_NO_PADDING = 'paddle-no-padding',
  PADDLE_POPUP_ORDER_LOADING = 'paddle-popup-order-loading',
  PADDLE_POPUP_ORDER_LOADING_ID = 'paddle-popup-order-loading_',
  PADDLE_POPUP_ORDER_SPINNER = 'paddle-popup-order-spinner',
  PADDLE_POPUP_ORDER_LOADING_TEXT = 'paddle-popup-order-loading-text',
  PADDLE_POPUP_ORDER_LOADING_TEXT_ID = 'paddle-popup-order-loading-text_',
  PADDLE_POPUP_ORDER_ERROR = 'paddle-popup-order-error',
  PADDLE_POPUP_ORDER_ERROR_ID = 'paddle-popup-order-error_',
  PADDLE_FADE_IN_DOWN = 'paddle-fadeInDown',
  PADDLE_POPUP_ORDER = 'paddle-popup-order',
  PADDLE_POPUP_ORDER_ID = 'paddle-popup-order_',
  PADDLE_FADE_OUT = 'paddle-fadeOut',
  PADDLE_FADE_OUT_UP_BIG = 'paddle-fadeOutUpBig',
  PADDLE_UPSELL_ORIGINAL = 'paddle_upsell_original',
  PADDLE_UPSELL_ICON = 'paddle_upsell-icon',
  PADDLE_UPSELL_WIDE_OVERLAY_ICON = 'paddle_upsell-wide-overlay-icon',
  PADDLE_UPSELL_WRAPPER = 'paddle_upsell-wrapper',
  PADDLE_UPSELL_WIDE_OVERLAY_WRAPPER = 'paddle_upsell-wide-overlay-wrapper',
  PADDLE_UPSELL_INTRO = 'paddle_upsell-intro',
  PADDLE_UPSELL_DATA = 'paddle_upsell-data',
  PADDLE_UPSELL_WIDE_OVERLAY_DATA = 'paddle_upsell-wide-overlay-data',
  PADDLE_UPSELL_TITLE = 'paddle_upsell-title',
  PADDLE_UPSELL_WIDE_OVERLAY_TITLE = 'paddle_upsell-wide-overlay-title',
  PADDLE_UPSELL_TEXT = 'paddle_upsell-text',
  PADDLE_UPSELL_WIDE_OVERLAY_TEXT = 'paddle_upsell-wide-overlay-text',
  PADDLE_UPSELL = 'paddle_upsell',
  PADDLE_UPSELL_THEME_LIGHT = 'paddle_upsell_theme_light',
  PADDLE_UPSELL_THEME_DARK = 'paddle_upsell_theme_dark',
  PADDLE_WIDE_OVERLAY_UPSELL = 'paddle_upsell-wide-overlay',
  PADDLE_UPSELL_CTA_CHECKBOX_CONTAINER = 'paddle_upsell-cta-checkbox-container',
  PADDLE_UPSELL_CTA = 'paddle_upsell-cta',
  PADDLE_UPSELL_WIDE_OVERLAY_CTA = 'paddle_upsell-wide-overlay-cta',
  PADDLE_UPSELL_CTA_CHECKBOX = 'paddle_upsell-cta-checkbox',
  PADDLE_UPSELL_CHECKBOX = 'paddle_upsell-checkbox',
  PADDLE_UPSELL_BUTTON = 'paddle_upsell_button',
  PADDLE_UPSELL_ORIGINAL_LINK = 'paddle_upsell_original_link',
  PADDLE_POPUP_ORDER_EMAIL_REMINDER = 'paddle-popup-order-emailed-reminder',
  PADDLE_POPUP_ORDER_PROBLEM_LINK = 'paddle-popup-order-problem-link',
  PADDLE_POPUP_ORDER_PROBLEM_LINK_ID = 'paddle-popup-order-problem-link_',
  PADDLE_POPUP_ORDER_PROBLEM = 'paddle-popup-order-problem',
  PADDLE_POPUP_ORDER_LOCKER = 'paddle-popup-order-locker',
  PADDLE_POPUP_ORDER_NO_LOCKER = 'paddle-popup-order-nolocker',
  PADDLE_POPUP_LOCKER_INSTRUCTIONS = 'paddle-popup-locker-instructions',
  PADDLE_POPUP_LOCKER_ROW = 'paddle-popup-locker-row',
  PADDLE_POPUP_LOCKER_ROW_TOP = 'paddle-popup-locker-row-top',
  PADDLE_POPUP_LOCKER_ROW_HEADING = 'paddle-popup-locker-row-heading',
  PADDLE_POPUP_LOCKER_LICENCE = 'paddle-popup-locker-license',
  PADDLE_POPUP_PRE = 'paddle-popup-pre',
  PADDLE_POPUP_ORDER_ORDER_DETAILS = 'paddle-popup-order-orderDetails',
  PADDLE_POPUP_ORDER_TOP = 'paddle-popup-order-top',
  PADDLE_POPUP_ORDER_ICON = 'paddle-popup-order-icon',
  PADDLE_POPUP_ORDER_PRODUCT = 'paddle-popup-order-product',
  PADDLE_POPUP_ORDER_SUMMARY = 'paddle-popup-order-summary',
  PADDLE_POPUP_ORDER_NUMBER = 'paddle-popup-order-number',
  PADDLE_POPUP_ORDER_AMOUNT = 'paddle-popup-order-amount',
  PADDLE_POPUP_ORDER_RECEIPT = 'paddle-popup-order-receipt',
  PADDLE_POPUP_ORDER_RECEIPT_BUTTON = 'paddle-popup-order-receipt-button',
  PADDLE_POPUP_ORDER_RECEIPT_BUTTON_ID = 'paddle-popup-order-receipt-button_',
  PADDLE_POPUP_LOCKER_ITEM = 'paddle-popup-locker-item',
  PADDLE_POPUP_LOCKER_ROW_BUTTON = 'paddle-popup-locker-row-button',
  PADDLE_POPUP_ORDER_DOWNLOAD_BUTTON_ID = 'paddle-popup-order-download-button_',
  PADDLE_POPUP_LOCKER_ROW_BUTTON_LINK = 'paddle-popup-locker-row-button-link',
}

export enum IDS {
  PADDLE_POPUP_MARKETING_CONSENT_MESSAGE = 'paddle-popup-marketing-consent-message',
  PADDLE_UPSELL_ID = 'paddle_upsell_',
  PADDLE_UPSELL_ORIGINAL = 'paddle_upsell_original',
  PADDLE_UPSELL_CHECKBOX = 'paddle_upsell-checkbox',
  PADDLE_UPSELL_CTA = 'paddle_upsell-cta',
}

export enum COLORS {
  //@ts-ignore
  '666666' = '#666666',
  'FFFFFF' = '#FFFFFF',
  '000000' = '#000000',
  '4CAF50' = '#4CAF50',
}

export enum THEME {
  GREEN = 'green',
  LIGHT = 'light',
  DARK = 'dark',
}

export enum DATA_ATTRIBUTES {
  DATA_INIT = 'data-init',
  DATA_DOWNLOAD = 'data-download',
  DATA_DOWNLOAD_URL = 'data-download-url',
  DATA_DOWNLOAD_PROMPT = 'data-download-prompt',
  DATA_DOWNLOAD_HEADING = 'data-download-heading',
  DATA_DOWNLOAD_SUBHEADING = 'data-download-subheading',
  DATA_DOWNLOAD_CTA = 'data-download-cta',
  DATA_VENDOR_NAME = 'data-vendor-name',
  DATA_ALLOW_QUANTITY = 'data-allow-quantity',
  DATA_PRODUCT = 'data-product',
  DATA_QUANTITY = 'data-quantity',
  DATA_THEME = 'data-theme',
  DATA_UPSELL_BUTTON = 'data-upsell-button',
  DATA_UPSELL_COUPON = 'data-upsell-coupon',
  DATA_UPSELL_ACTION = 'data-upsell-action',
  DATA_UPSELL_TITLE = 'data-upsell-title',
  DATA_UPSELL_TEXT = 'data-upsell-text',
  DATA_UPSELL = 'data-upsell',
  DATA_METHOD = 'data-method',
  DATA_DISABLE_LOGOUT = 'data-disable-logout',
  DATA_DISABLE_TITLE = 'data-title',
  DATA_REFERRER = 'data-referrer',
  DATA_MESSAGE = 'data-message',
  DATA_LOCALE = 'data-locale',
  DATA_COUPON = 'data-coupon',
  DATA_UPSELL_PASSTHROUGH = 'data-upsell-passthrough',
  DATA_PASSTHROUGH = 'data-passthrough',
  DATA_POSTCODE = 'data-postcode',
  DATA_COUNTRY = 'data-country',
  DATA_EMAIL = 'data-email',
  DATA_MARKETING_CONSENT = 'data-marketing-consent',
  DATA_DISPLAY_MODE_THEME = 'data-display-mode-theme',
  DATA_CHECKOUT_VERSION = 'data-checkout-version',
  DATA_TRIAL_DAYS_AUTH = 'data-trial-days-auth',
  DATA_AUTH = 'data-auth',
  DATA_TRIAL_DAYS = 'data-trial-days',
  DATA_PRICE = 'data-price',
  DATA_SUCCESS = 'data-success',
  DATA_CLOSE_CALLBACK = 'data-close-callback',
  DATA_LOAD_CALLBACK = 'data-load-callback',
  DATA_SUCCESS_CALLBACK = 'data-success-callback',
  DATA_OVERRIDE = 'data-override',
  DATA_TYPE = 'data-type',
  DATA_CUSTOM_DATA = 'data-custom-data',
  DATA_HIDE_TAX_LINK = 'data-hide-tax-link',
}
export enum BTN_ATTRIBUTES_KEYS {
  AUTH = 'auth',
  TYPE = 'type',
  EMAIL = 'email',
  THEME = 'theme',
  TITLE = 'title',
  PRICE = 'price',
  COUPON = 'coupon',
  UPSELL = 'upsell',
  METHOD = 'method',
  LOCALE = 'locale',
  PRODUCT = 'product',
  SUCCESS = 'success',
  COUNTRY = 'country',
  MESSAGE = 'message',
  POSTCODE = 'postcode',
  QUANTITY = 'quantity',
  OVERRIDE = 'override',
  IS_UPSELL = 'isUpsell',
  TRIAL_DAYS = 'trialDays',
  UPSELL_TEXT = 'upsellText',
  PASSTHROUGH = 'passthrough',
  UPSELL_TITLE = 'upsellTitle',
  UPSELL_ACTION = 'upsellAction',
  UPSELL_COUPON = 'upsellCoupon',
  LOAD_CALLBACK = 'loadCallback',
  CLOSE_CALLBACK = 'closeCallback',
  SUCCESS_CALLBACK = 'successCallback',
  TRIAL_DAYS_AUTH = 'trialDaysAuth',
  DISPLAY_MODE_THEME = 'displayModeTheme',
  MARKETING_CONSENT = 'marketingConsent',
  UPSELL_PASSTHROUGH = 'upsellPassthrough',
  REFERRING_DOMAIN = 'referring_domain',
  DISABLE_LOGOUT = 'disableLogout',
  ALLOW_QUANTITY = 'allowQuantity',
  CUSTOM_DATA = 'customData',
  HIDE_TAX_LINK = 'hideTaxLink',
}
