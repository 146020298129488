// Post-checkout Order Methods.
import { CLASSES, COLORS, LOG_LEVEL, POPUP_METHOD, POPUP_TYPE, getResource } from 'src/constants'

import { Environment } from 'src/config'

import {
  show,
  hide,
  ready,
  showPopup,
  hidePopup,
  nl2br,
  showLoading,
  hideLoading,
  logger,
  jsonp,
  OrderDataProps,
} from 'src/utils'

export enum OrderState {
  Processed = 'processed',
  Processing = 'processing',
}

interface PollingAttemptsProps {
  [checkoutHash: string]: number
}
interface PollingProps {
  [checkoutHash: string]: boolean
}

const pollAttempts: PollingAttemptsProps = {}
const maxPollAttempts = 10
const pollingTimeout = 3000
const polling: PollingProps = {}

export const getPopupHtml = (popupId: string, processingMessage: string) => {
  const env = Environment.get()
  let popupHtml = `<div class="${CLASSES.PADDLE_POPUP} ${CLASSES.PADDLE_ANIMATED} ${CLASSES.PADDLE_BOUNCE_IN}">`

  popupHtml += `<div class="${CLASSES.PADDLE_POPUP_CLOSE} ${CLASSES.PADDLE_INSET_CLOSE}">`
  popupHtml += `<a class="${CLASSES.PADDLE_POPUP_CLOSE_IMAGE}" href="javascript:;"><img src=${
    getResource(env).CLOSE_IMAGE_DARK
  } border="0" /></a>`
  popupHtml += '</div>'

  popupHtml += `<div class="${CLASSES.PADDLE_POPUP_INNER} ${CLASSES.PADDLE_NO_PADDING}" style="background-color: ${COLORS['FFFFFF']} !important;">`
  popupHtml += `<div class="${CLASSES.PADDLE_POPUP_ORDER_LOADING} ${CLASSES.PADDLE_POPUP_ORDER_LOADING_ID}${popupId}">`
  popupHtml += `<div class="${CLASSES.PADDLE_POPUP_ORDER_SPINNER}"><img src="${
    getResource(env).LOADING_GIF
  }" style="width: 50px; height: 50px;" /></div>`
  popupHtml += `<div class="${CLASSES.PADDLE_POPUP_ORDER_LOADING_TEXT} ${CLASSES.PADDLE_POPUP_ORDER_LOADING_TEXT_ID}${popupId}">${processingMessage}</div>`
  popupHtml += '</div>'

  popupHtml += `<div class="${CLASSES.PADDLE_POPUP_ORDER_ERROR} ${CLASSES.PADDLE_POPUP_ORDER_ERROR_ID}${popupId} ${CLASSES.PADDLE_FADE_IN_DOWN} ${CLASSES.PADDLE_HIDDEN}">`
  popupHtml += 'Your receipt and purchase information have been sent to the email address used during purchase.'
  popupHtml += '</div>'

  popupHtml += `<div class="${CLASSES.PADDLE_POPUP_ORDER} ${CLASSES.PADDLE_POPUP_ORDER_ID}${popupId} ${CLASSES.PADDLE_FADE_IN_DOWN} ${CLASSES.PADDLE_HIDDEN}">`
  popupHtml += 'Order details go here...'
  popupHtml += '</div>'
  popupHtml += '</div>'

  popupHtml += '</div>'

  return popupHtml
}

export const getPopupContent = (checkoutHash: string, popupId: string, data: OrderDataProps) => {
  let popupContent = `<div class="${CLASSES.PADDLE_POPUP_ORDER_ORDER_DETAILS}">`

  popupContent += `<div class="${CLASSES.PADDLE_POPUP_ORDER_TOP}">`
  popupContent += `<div class="${CLASSES.PADDLE_POPUP_ORDER_ICON}">`
  popupContent += '<img src="' + data.checkout.image_url + '" border="0" />'
  popupContent += '</div>'

  popupContent += `<div class="${CLASSES.PADDLE_POPUP_ORDER_PRODUCT}">`
  popupContent += data.checkout.title
  popupContent += '</div>'

  popupContent += '<div style="clear:both;"></div>'
  popupContent += '</div>'

  popupContent += `<div class="${CLASSES.PADDLE_POPUP_ORDER_SUMMARY}">`
  popupContent += `<div class="${CLASSES.PADDLE_POPUP_ORDER_NUMBER}">`
  popupContent += 'Order #' + data.order.order_id
  popupContent += '</div>'

  popupContent += `<div class="${CLASSES.PADDLE_POPUP_ORDER_AMOUNT}">`
  popupContent += data.order.formatted_total
  popupContent += '</div>'

  popupContent += `<div class="${CLASSES.PADDLE_POPUP_ORDER_RECEIPT}">`
  popupContent += `<a href="${data.order.receipt_url}" target="_blank" class="${CLASSES.PADDLE_POPUP_ORDER_RECEIPT_BUTTON} ${CLASSES.PADDLE_POPUP_ORDER_RECEIPT_BUTTON_ID}${popupId}">View Receipt</a>`
  popupContent += '</div>'

  popupContent += '<div style="clear:both;"></div>'
  popupContent += '</div>'

  if (data.order.has_locker) {
    popupContent += `<div class="${CLASSES.PADDLE_POPUP_ORDER_LOCKER}">`

    // @note Currently, it isn't a nice UX to display next-gen order lockers in the popup.
    // @todo Add product name per-locker to the API, and change this UI to work with next-gen orders.
    // @ts-ignore
    if (data.lockers.length > 1) {
      popupContent += `<div class="${CLASSES.PADDLE_POPUP_ORDER_NO_LOCKER}">`
      popupContent += '<strong>Thanks for your purchase!</strong><br /><br />'
      popupContent += `We've emailed your receipt and details of how to access your products to <strong>${data.order.customer.email}</strong>.`
      popupContent += '</div>'
    } else {
      // @note The forEach here isn't neccecary, but it's cumbersome to remove.
      // @ts-ignore
      data.lockers.forEach(function (locker) {
        popupContent += `<div class="${CLASSES.PADDLE_POPUP_LOCKER_ITEM}">`

        if (typeof locker.download != 'undefined' && locker.download !== '') {
          popupContent += `<div class="${CLASSES.PADDLE_POPUP_LOCKER_ROW_BUTTON}">`
          popupContent += `<a href="${locker.download}" target="_blank" class="${CLASSES.PADDLE_POPUP_LOCKER_ROW_BUTTON_LINK} ${CLASSES.PADDLE_POPUP_ORDER_DOWNLOAD_BUTTON_ID}${popupId}">Download</a>`
          popupContent += '</div>'
        }

        if (typeof locker.license_code != 'undefined' && locker.license_code !== '') {
          popupContent += `<div class="${CLASSES.PADDLE_POPUP_LOCKER_ROW}">`
          popupContent += `<div class="${CLASSES.PADDLE_POPUP_LOCKER_ROW_TOP}">`
          popupContent += `<div class="${CLASSES.PADDLE_POPUP_LOCKER_ROW_HEADING}">License Code</div>`
          popupContent += '<div style="clear:both;"></div>'
          popupContent += '</div>'
          popupContent += `<div class="${CLASSES.PADDLE_POPUP_LOCKER_LICENCE}">`
          popupContent += `<pre class="${CLASSES.PADDLE_POPUP_PRE}">${locker.license_code}</pre>`
          popupContent += '</div>'
          popupContent += '</div>'
        }

        if (typeof locker.instructions != 'undefined' && locker.instructions !== '') {
          // Instructions HTML is often too escaped.
          locker.instructions = locker.instructions.replace(/\\"/g, '"').trim()

          popupContent += `<div class="${CLASSES.PADDLE_POPUP_LOCKER_ROW}">`
          popupContent += `<div class="${CLASSES.PADDLE_POPUP_LOCKER_ROW_TOP}">`
          popupContent += `<div class="${CLASSES.PADDLE_POPUP_LOCKER_ROW_HEADING}">Instructions &amp; Information</div>`
          popupContent += '<div style="clear:both;"></div>'
          popupContent += '</div>'
          popupContent += `<div class="${CLASSES.PADDLE_POPUP_LOCKER_INSTRUCTIONS}">`
          popupContent += nl2br(locker.instructions)
          popupContent += '</div>'
          popupContent += '</div>'
        }

        popupContent += '</div>'
      })
    }

    popupContent += '</div>'
  } else {
    popupContent += `<div class="${CLASSES.PADDLE_POPUP_ORDER_NO_LOCKER}">`
    popupContent += `We've emailed details of how to access your purchases, as well as the information above to <strong>${data.order.customer.email}</strong>.`
    popupContent += '</div>'
  }

  popupContent += `<div class="${CLASSES.PADDLE_POPUP_ORDER_PROBLEM}">`
  popupContent += `Something wrong? <a href="mailto:help+pjs_${data.order.order_id}_${checkoutHash}_order@paddle.com" class="${CLASSES.PADDLE_POPUP_ORDER_PROBLEM_LINK} ${CLASSES.PADDLE_POPUP_ORDER_PROBLEM_LINK_ID}${popupId}">Contact Support</a>`
  popupContent += `<div class="${CLASSES.PADDLE_POPUP_ORDER_EMAIL_REMINDER}">We've also emailed the above information to: <strong>${data.order.customer.email}</strong></div>`
  popupContent += '</div>'

  return popupContent
}

export function orderDetailsPopup(checkoutHash: string, processingMessage = 'Fetching Order Details...') {
  const popupId = '_' + Math.ceil(Math.random() * 10000000)

  ready(_onReady(popupId, checkoutHash, processingMessage))
}

export const _onReady = (popupId: string, checkoutHash: string, processingMessage: string) => () => {
  let body = document.getElementsByTagName('body')[0]
  let orderPopup = document.createElement('div')
  orderPopup.setAttribute(
    'class',
    `${CLASSES.PADDLE_RESET} ${CLASSES.PADDLE_POPUP_CONTAINER} ${CLASSES.PADDLE_POPUP_INSTANCE_ID}${popupId} ${CLASSES.PADDLE_ANIMATED} ${CLASSES.PADDLE_FADE_IN} ${CLASSES.PADDLE_HIDDEN}`,
  )
  orderPopup.innerHTML = getPopupHtml(popupId, processingMessage)
  body.appendChild(orderPopup)

  let close = document
    .getElementsByClassName(`${CLASSES.PADDLE_POPUP_INSTANCE_ID}${popupId}`)[0]
    .getElementsByClassName(CLASSES.PADDLE_POPUP_CLOSE_IMAGE)[0]
  //@ts-ignore
  close.onclick = function (e) {
    e.preventDefault()
    hidePopup(popupId, POPUP_TYPE.ORDER)
  }

  showPopup(popupId, POPUP_METHOD.ORDER, POPUP_TYPE.ORDER)

  orderDetails(checkoutHash, _handleOrderDetails(checkoutHash, popupId), false)
}

export const _handleOrderDetails = (checkoutHash: string, popupId: string) => (data: OrderDataProps | boolean) => {
  if (data) {
    if ((data as OrderDataProps).state === OrderState.Processed) {
      let orderPopupContent = document.getElementsByClassName(`${CLASSES.PADDLE_POPUP_ORDER_ID}${popupId}`)[0]
      orderPopupContent.innerHTML = getPopupContent(checkoutHash, popupId, data as OrderDataProps)

      hide(document.getElementsByClassName(`${CLASSES.PADDLE_POPUP_ORDER_LOADING_ID}${popupId}`)[0])

      show(orderPopupContent)
    } else {
      hide(document.getElementsByClassName(`${CLASSES.PADDLE_POPUP_ORDER_LOADING_ID}${popupId}`)[0])
      show(document.getElementsByClassName(`${CLASSES.PADDLE_POPUP_ORDER_ERROR_ID}${popupId}`)[0])
    }
  } else {
    hide(document.getElementsByClassName(`${CLASSES.PADDLE_POPUP_ORDER_LOADING_ID}${popupId}`)[0])
    show(document.getElementsByClassName(`${CLASSES.PADDLE_POPUP_ORDER_ERROR_ID}${popupId}`)[0])
  }
}

export function orderDetails(checkoutHash: string, callback?: OrderCallbackProps, showLoader = true) {
  polling[checkoutHash] = typeof polling[checkoutHash] != 'undefined' ? polling[checkoutHash] : false

  if (!polling[checkoutHash]) {
    if (showLoader) {
      showLoading()
    }

    poll(checkoutHash, _handlePolling(showLoader, callback))
  } else {
    logger.log('Call to Order.details() rejected as a call is already in progress.', LOG_LEVEL.ERROR, true)
  }
}

export const _handlePolling =
  (showLoader: boolean, callback?: OrderCallbackProps) => (data: OrderDataProps | boolean) => {
    if (showLoader) {
      hideLoading()
    }

    if (typeof callback == 'function') {
      logger.log('Order details API response successfully passed to callback: ' + callback)
      callback(data)
    } else {
      logger.log('No callback specified for Order Data success.', LOG_LEVEL.WARNING, true)
    }
  }

export interface OrderCallbackProps {
  (data: OrderDataProps | boolean): void
}

function poll(checkoutHash: string, callback: OrderCallbackProps) {
  polling[checkoutHash] = true
  jsonp(Environment.defaults().orderApi + '?checkout_id=' + checkoutHash, _handleOrderResponse(checkoutHash, callback))
}

export const _handleOrderResponse = (checkoutHash: string, callback: OrderCallbackProps) => (data: OrderDataProps) => {
  if (typeof data.success != 'undefined' && !data.success) {
    polling[checkoutHash] = false
    hideLoading()

    // @ts-ignore
    logger.log(data.error.message, LOG_LEVEL.ERROR, true)

    if (typeof callback == 'function') {
      callback(false)
    } else {
      alert('Sorry, there was an error retrieving the requested order details.')
    }
  } else {
    if (data.state !== OrderState.Processed) {
      pollAttempts[checkoutHash] = typeof pollAttempts[checkoutHash] != 'undefined' ? pollAttempts[checkoutHash] : 0

      if (pollAttempts[checkoutHash] <= maxPollAttempts) {
        pollAttempts[checkoutHash]++

        setTimeout(function () {
          poll(checkoutHash, callback)
        }, pollingTimeout)
      } else {
        polling[checkoutHash] = false
        hideLoading()

        logger.log(
          'Order stopped polling as maximum attempts of ' + maxPollAttempts + ' reached.',
          LOG_LEVEL.ERROR,
          true,
        )

        if (typeof callback == 'function') {
          callback(false)
        } else {
          alert('Your order has been completed, please check your email for further information.')
        }
      }
    } else {
      polling[checkoutHash] = false

      logger.log('Order details retrieved successfully from Paddle API.')

      if (typeof callback == 'function') {
        callback(data)
      } else {
        logger.log('Callback passed to orderDetails() is not a function.', LOG_LEVEL.WARNING)
      }
    }
  }
}
